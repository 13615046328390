// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles */
.sponsor-container {
  text-align: center;
}

.sponsor-title {
  font-family: 'Mokoto';
  font-weight: 400;
  color: white;
  font-size: 3rem;
  margin-top: 50px;
}

.sponsor-content {
  padding: 2% 10%;
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.content-title {
  font-family: 'Poppins';
  font-size: 30px;
}

.button{
	width: fit-content;
}


.content-iframe {
  width: 100%;
  height: 550px;
  border: none;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .sponsor-title {
    font-size: 2rem;
    margin-top: 30px;
  }

  .content-title {
    font-size: 18px;
  }

  .content-iframe {
    height: 400px;
  }
}
`, "",{"version":3,"sources":["webpack://./frontend/src/Pages/SponsorUsPage/SponsorUsPage.css"],"names":[],"mappings":"AAAA,WAAW;AACX;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,eAAe;AACjB;;AAEA;CACC,kBAAkB;AACnB;;;AAGA;EACE,WAAW;EACX,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,kBAAkB;AAClB;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["/* Styles */\n.sponsor-container {\n  text-align: center;\n}\n\n.sponsor-title {\n  font-family: 'Mokoto';\n  font-weight: 400;\n  color: white;\n  font-size: 3rem;\n  margin-top: 50px;\n}\n\n.sponsor-content {\n  padding: 2% 10%;\n}\n\n.content-section {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-bottom: 20px;\n}\n\n.content-title {\n  font-family: 'Poppins';\n  font-size: 30px;\n}\n\n.button{\n\twidth: fit-content;\n}\n\n\n.content-iframe {\n  width: 100%;\n  height: 550px;\n  border: none;\n}\n\n.centered-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n/* Mobile Styles */\n@media (max-width: 768px) {\n  .sponsor-title {\n    font-size: 2rem;\n    margin-top: 30px;\n  }\n\n  .content-title {\n    font-size: 18px;\n  }\n\n  .content-iframe {\n    height: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
