// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    width: 100vw;  
}

.Tab {
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column; 
        flex-wrap: nowrap;
        align-items: center;
        justify-contentZ:space-evenly;
        align-items: normal;
        gap: 20px; /* Adjust the gap between Tabs */
        font-family: 'Mokoto';
        color: #FFFFFF;
        margin-top: -100px;
        margin-bottom: 20px;
    }

    @media (max-width: 750px) {
        margin-top: -150px;
        transform: scale(0.88);
        flex-direction: column; 
        align-items: center; 
    }

    @media (max-width: 850px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        font-size: 20px; 
        user-select: none; 
        align-items: flex-start;
              
    }
    @media (max-width: 1050px) and (max-height: 620px) {
        margin-top: 25px;
    }
}

.otherprizes {
    @media (max-width: 390px) {
        margin-top: -120px;
    }
}`, "",{"version":3,"sources":["webpack://./frontend/src/Pages/PrizesPage/style/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,iBAAiB;QACjB,mBAAmB;QACnB,6BAA6B;QAC7B,mBAAmB;QACnB,SAAS,EAAE,gCAAgC;QAC3C,qBAAqB;QACrB,cAAc;QACd,kBAAkB;QAClB,mBAAmB;IACvB;;IAEA;QACI,kBAAkB;QAClB,sBAAsB;QACtB,sBAAsB;QACtB,mBAAmB;IACvB;;IAEA;QACI,aAAa;QACb,eAAe;QACf,mBAAmB;QACnB,mBAAmB;QACnB,eAAe;QACf,mBAAmB;QACnB,eAAe;QACf,iBAAiB;QACjB,uBAAuB;;IAE3B;IACA;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["body {\n    width: 100vw;  \n}\n\n.Tab {\n    @media (max-width: 600px) {\n        display: flex;\n        flex-direction: column; \n        flex-wrap: nowrap;\n        align-items: center;\n        justify-contentZ:space-evenly;\n        align-items: normal;\n        gap: 20px; /* Adjust the gap between Tabs */\n        font-family: 'Mokoto';\n        color: #FFFFFF;\n        margin-top: -100px;\n        margin-bottom: 20px;\n    }\n\n    @media (max-width: 750px) {\n        margin-top: -150px;\n        transform: scale(0.88);\n        flex-direction: column; \n        align-items: center; \n    }\n\n    @media (max-width: 850px) {\n        display: flex;\n        flex-wrap: wrap;\n        flex-direction: row;\n        align-items: center;\n        cursor: pointer;\n        white-space: nowrap;\n        font-size: 20px; \n        user-select: none; \n        align-items: flex-start;\n              \n    }\n    @media (max-width: 1050px) and (max-height: 620px) {\n        margin-top: 25px;\n    }\n}\n\n.otherprizes {\n    @media (max-width: 390px) {\n        margin-top: -120px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
