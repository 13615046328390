// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css or styles.scss */
.card-container {
  width: 21rem;
  background: transparent;
  border: none;
  transition: .5s;
  }
  
  .card-container:hover {
    background: rgba(142, 142, 142, 0.27);
    scale: 1.04;
    box-shadow: 0 0 15px red;
  }`, "",{"version":3,"sources":["webpack://./frontend/src/Pages/WorkshopPage/style.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf;;EAEA;IACE,qCAAqC;IACrC,WAAW;IACX,wBAAwB;EAC1B","sourcesContent":["/* styles.css or styles.scss */\n.card-container {\n  width: 21rem;\n  background: transparent;\n  border: none;\n  transition: .5s;\n  }\n  \n  .card-container:hover {\n    background: rgba(142, 142, 142, 0.27);\n    scale: 1.04;\n    box-shadow: 0 0 15px red;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
