// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px; /* adjust the value as needed */
    overflow-y: auto;
    flex: 1;
    height: fit-content;
}

.schedule-page-container h1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./frontend/src/Pages/SchedulePage/SchedulePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS,EAAE,+BAA+B;IAC1C,gBAAgB;IAChB,OAAO;IACP,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".schedule-page-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 20px; /* adjust the value as needed */\n    overflow-y: auto;\n    flex: 1;\n    height: fit-content;\n}\n\n.schedule-page-container h1 {\n    font-size: 40px;\n    font-weight: 700;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
