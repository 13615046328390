// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Fonts/Mokoto/mokoto.regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Fonts/Agrandir/Agrandir-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Fonts/Poppins/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Fonts/Poppins/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Fonts/Poppins/Poppins-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'Mokoto';
	src: local('Mokoto'),
		url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
	font-weight: regular;
}

@font-face {
	font-family: 'Agrandir';
	src: local('Agrandir'),
		url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
	font-weight: regular;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins'),
		url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
	font-weight: regular;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins-Bold"),
        url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
    font-weight: bold;
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins Light'),
		url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
	font-weight: light;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins ExtraLight"),
        url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
    font-weight: lighter;
}
`, "",{"version":3,"sources":["webpack://./frontend/src/fonts.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB;4DAC4D;CAC5D,oBAAoB;AACrB;;AAEA;CACC,uBAAuB;CACvB;4DACgE;CAChE,oBAAoB;AACrB;;AAEA;CACC,sBAAsB;CACtB;4DAC8D;CAC9D,oBAAoB;AACrB;;AAEA;IACI,sBAAsB;IACtB;kEAC8D;IAC9D,iBAAiB;AACrB;;AAEA;CACC,sBAAsB;CACtB;4DAC4D;CAC5D,kBAAkB;AACnB;;AAEA;IACI,sBAAsB;IACtB;kEACoE;IACpE,oBAAoB;AACxB","sourcesContent":["@font-face {\n\tfont-family: 'Mokoto';\n\tsrc: local('Mokoto'),\n\t\turl('./Fonts/Mokoto/mokoto.regular.ttf') format('truetype');\n\tfont-weight: regular;\n}\n\n@font-face {\n\tfont-family: 'Agrandir';\n\tsrc: local('Agrandir'),\n\t\turl('./Fonts/Agrandir/Agrandir-Regular.otf') format('opentype');\n\tfont-weight: regular;\n}\n\n@font-face {\n\tfont-family: 'Poppins';\n\tsrc: local('Poppins'),\n\t\turl('./Fonts/Poppins/Poppins-Regular.ttf') format('truetype');\n\tfont-weight: regular;\n}\n\n@font-face {\n    font-family: \"Poppins\";\n    src: local(\"Poppins-Bold\"),\n        url(\"./Fonts/Poppins/Poppins-Bold.ttf\") format(\"truetype\");\n    font-weight: bold;\n}\n\n@font-face {\n\tfont-family: 'Poppins';\n\tsrc: local('Poppins Light'),\n\t\turl('./Fonts/Poppins/Poppins-Light.ttf') format('truetype');\n\tfont-weight: light;\n}\n\n@font-face {\n    font-family: \"Poppins\";\n    src: local(\"Poppins ExtraLight\"),\n        url(\"./Fonts/Poppins/Poppins-ExtraLight.ttf\") format(\"truetype\");\n    font-weight: lighter;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
