// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-navbar {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.mobile-logo {
    position: relative;
    left: 15px;
    margin-left: 15px;
}


.bm-burger-button {
    position: relative;
    width: 30px;
    height: 24px;
    left: 20px;
}

.bm-burger-bars {
  background: white;
}

.bm-menu {
  background: var(--black);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.menu-item {
  font-family: 'Mokoto', sans-serif;
  color: white;
  font-size: 6vw;
  margin-bottom: 1em;
  text-decoration: none;
}

.menu-item:active {
  color: var(--red);
}

.menu-item:link {
  color: white !important;
}

.menu-item:visited {
  color: white !important;
}

.menu-item:hover {
  color: var(--red) !important;
}


#faq {
  margin-bottom: 1em !important;
}

@media (min-width: 1024px) {
  .menu-item {
      font-size: 2em; /* Set a maximum font size for large screens */
  }
}
`, "",{"version":3,"sources":["webpack://./frontend/src/Components/NavBar/MobileNavBar.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,iBAAiB;AACrB;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,UAAU;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;AAC9B;;;AAGA;EACE,6BAA6B;AAC/B;;AAEA;EACE;MACI,cAAc,EAAE,8CAA8C;EAClE;AACF","sourcesContent":[".mobile-navbar {\n    padding-top: 20px;\n    padding-bottom: 20px;\n    display: flex;\n    align-items: center;\n}\n\n.mobile-logo {\n    position: relative;\n    left: 15px;\n    margin-left: 15px;\n}\n\n\n.bm-burger-button {\n    position: relative;\n    width: 30px;\n    height: 24px;\n    left: 20px;\n}\n\n.bm-burger-bars {\n  background: white;\n}\n\n.bm-menu {\n  background: var(--black);\n  padding: 2.5em 1.5em 0;\n  font-size: 1.15em;\n}\n\n.menu-item {\n  font-family: 'Mokoto', sans-serif;\n  color: white;\n  font-size: 6vw;\n  margin-bottom: 1em;\n  text-decoration: none;\n}\n\n.menu-item:active {\n  color: var(--red);\n}\n\n.menu-item:link {\n  color: white !important;\n}\n\n.menu-item:visited {\n  color: white !important;\n}\n\n.menu-item:hover {\n  color: var(--red) !important;\n}\n\n\n#faq {\n  margin-bottom: 1em !important;\n}\n\n@media (min-width: 1024px) {\n  .menu-item {\n      font-size: 2em; /* Set a maximum font size for large screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
