// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 700px) {

    h1 {
        font-size: 30px;
        text-align: center;
    }

    h2 {
        font-size: 15px;
        text-align: center;
    }
}

.hack-button {
    position: relative;
    font-family: Poppins;
    /* font-weight: 500; */
    font-size: 18px;
    letter-spacing: 0.05em;
    border-radius: 0.8em;
    border: none;
    background: var(--red);
    overflow: hidden;
}

.hack-button:hover {
    background: linear-gradient(to right, var(--red), #d50110, #560002);
    /* transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1); */
}

h2 {
    font-Family: 'Poppins';
    text-align: center;
}

@media screen and (max-width: 900px) {
    .mainNav {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./frontend/src/Pages/MapsPage/maps.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,eAAe;QACf,kBAAkB;IACtB;;IAEA;QACI,eAAe;QACf,kBAAkB;IACtB;AACJ;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,sBAAsB;IACtB,eAAe;IACf,sBAAsB;IACtB,oBAAoB;IACpB,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,mEAAmE;IACnE,6DAA6D;AACjE;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media screen and (max-width: 700px) {\n\n    h1 {\n        font-size: 30px;\n        text-align: center;\n    }\n\n    h2 {\n        font-size: 15px;\n        text-align: center;\n    }\n}\n\n.hack-button {\n    position: relative;\n    font-family: Poppins;\n    /* font-weight: 500; */\n    font-size: 18px;\n    letter-spacing: 0.05em;\n    border-radius: 0.8em;\n    border: none;\n    background: var(--red);\n    overflow: hidden;\n}\n\n.hack-button:hover {\n    background: linear-gradient(to right, var(--red), #d50110, #560002);\n    /* transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1); */\n}\n\nh2 {\n    font-Family: 'Poppins';\n    text-align: center;\n}\n\n@media screen and (max-width: 900px) {\n    .mainNav {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
