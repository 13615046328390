// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-background{
  fill: none;
  stroke: #ddd;
}

.circle-progress{
  fill: none;
  /* stroke: var(--none); */
  stroke-linecap: round;
  stroke-linejoin: round;

}

.circle-text{
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./frontend/src/Components/Timmer/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,qBAAqB;EACrB,sBAAsB;;AAExB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".circle-background{\n  fill: none;\n  stroke: #ddd;\n}\n\n.circle-progress{\n  fill: none;\n  /* stroke: var(--none); */\n  stroke-linecap: round;\n  stroke-linejoin: round;\n\n}\n\n.circle-text{\n  font-size: 3rem;\n  font-weight: bold;\n  text-align: center;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
